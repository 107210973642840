var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "close-on-content-click": true, "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass:
                          "font-weight-bold caption mr-1 d-flex align-center",
                        attrs: { color: "primary", text: "", outlined: "" }
                      },
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("desktop_windows")]
                      ),
                      _vm._v("run from application ")
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.appTypeMetaData, function(app, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      return _vm.setAppConnector(app.name)
                    }
                  }
                },
                [
                  _c("v-list-item-title", [
                    _c("div", { staticClass: "d-flex align-center" }, [
                      _c("img", {
                        attrs: {
                          width: "35",
                          height: "35",
                          src: _vm.getAppLink(app.short_name)
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass:
                            "ml-3 font-weight-bold subtitle-2 primary--text"
                        },
                        [_vm._v(_vm._s(app.name))]
                      )
                    ])
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "50%" },
          model: {
            value: _vm.getDataDialog,
            callback: function($$v) {
              _vm.getDataDialog = $$v
            },
            expression: "getDataDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _vm.fullConnectorData
                ? _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center justify-end py-3" },
                        [
                          _c("img", {
                            attrs: {
                              width: "35",
                              height: "35",
                              src: _vm.getAppLink(
                                _vm.fullConnectorData.app_short_name
                              )
                            }
                          }),
                          _c("span", { staticClass: "ml-2 subtitle-2" }, [
                            _vm._v(_vm._s(_vm.fullConnectorData.appName))
                          ])
                        ]
                      ),
                      _c(
                        "v-radio-group",
                        {
                          model: {
                            value: _vm.selectedConnectorOption,
                            callback: function($$v) {
                              _vm.selectedConnectorOption = $$v
                            },
                            expression: "selectedConnectorOption"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: "Connect from Nuvolos",
                              value: _vm.connectorOptions.FROM_NUVOLOS
                            }
                          }),
                          _c(
                            "v-card",
                            {
                              attrs: {
                                elevation:
                                  _vm.selectedConnectorOption ===
                                  _vm.connectorOptions.FROM_NUVOLOS
                                    ? 2
                                    : 0,
                                disabled:
                                  _vm.selectedConnectorOption ===
                                  _vm.connectorOptions.FROM_LOCAL_COMPUTER,
                                outlined: ""
                              }
                            },
                            [
                              _c("v-card-text", [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c(
                                      "v-banner",
                                      { attrs: { "two-line": "" } },
                                      [
                                        _c(
                                          "v-avatar",
                                          {
                                            attrs: {
                                              slot: "icon",
                                              color: "white",
                                              size: "60"
                                            },
                                            slot: "icon"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  icon: "info",
                                                  color: "info"
                                                }
                                              },
                                              [_vm._v(" info ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "info--text subtitle-2"
                                          },
                                          [
                                            _vm._v(
                                              " Paste the code shown below into a running " +
                                                _vm._s(
                                                  _vm.fullConnectorData.appName
                                                ) +
                                                " session in Nuvolos to execute your query. "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-space-between mt-5",
                                        staticStyle: {
                                          "background-color":
                                            "rgba(23, 168, 193, 0.1)",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mb-0 mt-0 ml-2 font-weight-bold caption",
                                            staticStyle: {
                                              color: "rgba(0, 0, 0, 0.54)"
                                            }
                                          },
                                          [_vm._v("Nuvolos Connector")]
                                        ),
                                        _c("CopyToClipboard", {
                                          attrs: {
                                            textToCopy:
                                              _vm.fullConnectorData
                                                .nuvolosConnector,
                                            buttonClass:
                                              "font-weight-bold caption",
                                            buttonColor: "primary",
                                            isTextButton: true,
                                            buttonName: "copy to clipboard"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("MonacoEditor", {
                                      staticClass: "mb-3",
                                      style: {
                                        height:
                                          _vm.fullConnectorData.nvConnectorLines
                                      },
                                      attrs: {
                                        value:
                                          _vm.fullConnectorData
                                            .nuvolosConnector,
                                        language:
                                          _vm.fullConnectorData.language,
                                        options: {
                                          minimap: { enabled: false },
                                          fontSize: 14,
                                          readOnly: true,
                                          renderLineHighlight: false,
                                          automaticLayout: true,
                                          autoIndent: true,
                                          lineNumbersMinChars: 3,
                                          scrollbar: { vertical: "hidden" },
                                          scrollBeyondLastLine: false,
                                          smoothScrolling: true
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                          _c("v-radio", {
                            staticClass: "mt-7",
                            attrs: {
                              label: "Connect from local computer",
                              value: _vm.connectorOptions.FROM_LOCAL_COMPUTER
                            }
                          }),
                          _c(
                            "v-card",
                            {
                              attrs: {
                                elevation:
                                  _vm.selectedConnectorOption ===
                                  _vm.connectorOptions.FROM_LOCAL_COMPUTER
                                    ? 2
                                    : 0,
                                disabled:
                                  _vm.selectedConnectorOption ===
                                  _vm.connectorOptions.FROM_NUVOLOS,
                                outlined: ""
                              }
                            },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-banner",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          attrs: {
                                            slot: "icon",
                                            color: "white",
                                            size: "60"
                                          },
                                          slot: "icon"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                icon: "info",
                                                color: "info"
                                              }
                                            },
                                            [_vm._v(" info ")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "ol",
                                        { staticClass: "info--text" },
                                        [
                                          _vm._l(
                                            _vm.fullConnectorData
                                              .fromLocalComputerMessage,
                                            function(line, index) {
                                              return _c(
                                                "li",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "info--text subtitle-2"
                                                },
                                                [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(line)
                                                    }
                                                  })
                                                ]
                                              )
                                            }
                                          ),
                                          _c("li", [
                                            _vm._v(
                                              " Please follow the setup steps detailed "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    _vm.fullConnectorData
                                                      .externalConfigLink,
                                                  target: "_blank"
                                                }
                                              },
                                              [_vm._v("here")]
                                            ),
                                            _vm._v(
                                              " to configure your local machine. "
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              " Paste the code shown below into a " +
                                                _vm._s(
                                                  _vm.fullConnectorData.appName
                                                ) +
                                                " editor in your local computer to run your query. "
                                            )
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between",
                                      staticStyle: {
                                        "background-color":
                                          "rgba(23, 168, 193, 0.1)",
                                        width: "100%"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mb-0 mt-0 ml-2 font-weight-bold caption",
                                          staticStyle: {
                                            color: "rgba(0, 0, 0, 0.54)"
                                          }
                                        },
                                        [_vm._v("Local Connector")]
                                      ),
                                      _c("CopyToClipboard", {
                                        attrs: {
                                          textToCopy:
                                            _vm.fullConnectorData
                                              .localComputerConnector,
                                          buttonClass:
                                            "font-weight-bold caption",
                                          buttonColor: "primary",
                                          isTextButton: true,
                                          buttonName: "copy to clipboard"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("MonacoEditor", {
                                    staticClass: "mb-3",
                                    style: {
                                      height:
                                        _vm.fullConnectorData
                                          .localConnectorLines
                                    },
                                    attrs: {
                                      value:
                                        _vm.fullConnectorData
                                          .localComputerConnector,
                                      language: _vm.fullConnectorData.language,
                                      options: {
                                        minimap: { enabled: false },
                                        fontSize: 14,
                                        readOnly: true,
                                        renderLineHighlight: false,
                                        automaticLayout: true,
                                        autoIndent: true,
                                        lineNumbersMinChars: 3,
                                        scrollbar: { vertical: "hidden" },
                                        scrollBeyondLastLine: false,
                                        smoothScrolling: true
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.getDataDialog = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }