<template>
    <div>
        <v-menu :close-on-content-click="true" offset-y>
            <template v-slot:activator="{ on }">
                <v-btn class="font-weight-bold caption mr-1 d-flex align-center" color="primary" text outlined v-on="on"
                    ><v-icon small class="mr-1">desktop_windows</v-icon>run from application
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(app, index) in appTypeMetaData" :key="index" @click="setAppConnector(app.name)">
                    <v-list-item-title>
                        <div class="d-flex align-center">
                            <img width="35" height="35" :src="getAppLink(app.short_name)" />
                            <span class="ml-3 font-weight-bold subtitle-2 primary--text">{{ app.name }}</span>
                        </div>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="getDataDialog" max-width="50%">
            <v-card>
                <v-card-text v-if="fullConnectorData">
                    <div class="d-flex align-center justify-end py-3">
                        <img width="35" height="35" :src="getAppLink(fullConnectorData.app_short_name)" />
                        <span class="ml-2 subtitle-2">{{ fullConnectorData.appName }}</span>
                    </div>
                    <v-radio-group v-model="selectedConnectorOption">
                        <v-radio label="Connect from Nuvolos" :value="connectorOptions.FROM_NUVOLOS"></v-radio>
                        <v-card
                            :elevation="selectedConnectorOption === connectorOptions.FROM_NUVOLOS ? 2 : 0"
                            :disabled="selectedConnectorOption === connectorOptions.FROM_LOCAL_COMPUTER"
                            outlined
                        >
                            <v-card-text>
                                <div class="d-flex flex-column">
                                    <v-banner two-line>
                                        <v-avatar slot="icon" color="white" size="60">
                                            <v-icon icon="info" color="info"> info </v-icon>
                                        </v-avatar>
                                        <span class="info--text subtitle-2">
                                            Paste the code shown below into a running {{ fullConnectorData.appName }} session in Nuvolos to execute your query.
                                        </span>
                                    </v-banner>
                                    <div class="d-flex align-center justify-space-between mt-5" style="background-color: rgba(23, 168, 193, 0.1); width: 100%">
                                        <span class="mb-0 mt-0 ml-2 font-weight-bold caption" style="color: rgba(0, 0, 0, 0.54)">Nuvolos Connector</span>
                                        <CopyToClipboard
                                            :textToCopy="fullConnectorData.nuvolosConnector"
                                            buttonClass="font-weight-bold caption"
                                            buttonColor="primary"
                                            :isTextButton="true"
                                            buttonName="copy to clipboard"
                                        ></CopyToClipboard>
                                    </div>
                                    <MonacoEditor
                                        class="mb-3"
                                        :style="{ height: fullConnectorData.nvConnectorLines }"
                                        :value="fullConnectorData.nuvolosConnector"
                                        :language="fullConnectorData.language"
                                        :options="{
                                            minimap: { enabled: false },
                                            fontSize: 14,
                                            readOnly: true,
                                            renderLineHighlight: false,
                                            automaticLayout: true,
                                            autoIndent: true,
                                            lineNumbersMinChars: 3,
                                            scrollbar: { vertical: 'hidden' },
                                            scrollBeyondLastLine: false,
                                            smoothScrolling: true
                                        }"
                                    />
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-radio class="mt-7" label="Connect from local computer" :value="connectorOptions.FROM_LOCAL_COMPUTER"></v-radio>
                        <v-card
                            :elevation="selectedConnectorOption === connectorOptions.FROM_LOCAL_COMPUTER ? 2 : 0"
                            :disabled="selectedConnectorOption === connectorOptions.FROM_NUVOLOS"
                            outlined
                        >
                            <v-card-text>
                                <v-banner class="mb-2">
                                    <v-avatar slot="icon" color="white" size="60">
                                        <v-icon icon="info" color="info"> info </v-icon>
                                    </v-avatar>
                                    <ol class="info--text">
                                        <li v-for="(line, index) in fullConnectorData.fromLocalComputerMessage" class="info--text subtitle-2" :key="index">
                                            <span v-html="line"></span>
                                        </li>
                                        <li>
                                            Please follow the setup steps detailed <a :href="fullConnectorData.externalConfigLink" target="_blank">here</a> to
                                            configure your local machine.
                                        </li>
                                        <li>
                                            Paste the code shown below into a {{ fullConnectorData.appName }} editor in your local computer to run your query.
                                        </li>
                                    </ol>
                                </v-banner>
                                <div class="d-flex align-center justify-space-between" style="background-color: rgba(23, 168, 193, 0.1); width: 100%">
                                    <span class="mb-0 mt-0 ml-2 font-weight-bold caption" style="color: rgba(0, 0, 0, 0.54)">Local Connector</span>
                                    <CopyToClipboard
                                        :textToCopy="fullConnectorData.localComputerConnector"
                                        buttonClass="font-weight-bold caption"
                                        buttonColor="primary"
                                        :isTextButton="true"
                                        buttonName="copy to clipboard"
                                    ></CopyToClipboard>
                                </div>
                                <MonacoEditor
                                    class="mb-3"
                                    :style="{ height: fullConnectorData.localConnectorLines }"
                                    :value="fullConnectorData.localComputerConnector"
                                    :language="fullConnectorData.language"
                                    :options="{
                                        minimap: { enabled: false },
                                        fontSize: 14,
                                        readOnly: true,
                                        renderLineHighlight: false,
                                        automaticLayout: true,
                                        autoIndent: true,
                                        lineNumbersMinChars: 3,
                                        scrollbar: { vertical: 'hidden' },
                                        scrollBeyondLastLine: false,
                                        smoothScrolling: true
                                    }"
                                />
                            </v-card-text>
                        </v-card>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="getDataDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const MonacoEditor = () => import('vue-monaco')
export default {
    name: 'GetTableData',
    mixins: [appTypeAndImageLink],
    components: {
        MonacoEditor,
        CopyToClipboard
    },
    props: {
        sqlText: String
    },
    data() {
        return {
            getDataDialog: false,
            selectedConnectorOption: 'nuvolos',
            connectorOptions: {
                FROM_NUVOLOS: 'nuvolos',
                FROM_LOCAL_COMPUTER: 'computer'
            },
            selectedAppType: null,
            query: 'SELECT * FROM SOMETHING',
            appTypeMetaData: [
                {
                    name: 'RStudio',
                    short_name: 'rstudio'
                },
                {
                    name: 'Matlab',
                    short_name: 'matlab'
                },
                {
                    name: 'Python',
                    short_name: 'jupyterlab'
                },
                {
                    name: 'Stata',
                    short_name: 'stata'
                }
            ]
        }
    },
    methods: {
        setAppConnector: function(app) {
            this.$data.getDataDialog = true
            this.$data.selectedAppType = app
        },
        scriptLines(script) {
            if (script) {
                if (script.split('\n').length === 1) {
                    return '32px'
                } else {
                    return script.split('\n').length * 22 + 'px'
                }
            }
        },
        queryTextOneLine(quoteChange) {
            if (this.sqlText) {
                if (quoteChange === 'escapeDoubleQuote') {
                    return this.sqlText.replace(/\n/g, ' ').replace(/"/g, '\\"')
                } else if (quoteChange === 'singleToTwoSingle') {
                    return this.sqlText.replace(/\n/g, ' ').replace(/'/g, "''")
                }
            }
            return ''
        },
        checkApplicationType(application, expectedType) {
            if (application.toUpperCase() === expectedType) {
                return true
            }
        },
        nuvolosConnectorMessage() {}
    },
    computed: {
        ...mapState('userStore', ['snowflakeTokens']),
        ...mapState('snapshotStore', ['tablesPath']),
        fullConnectorData() {
            if (this.$data.selectedAppType !== null && this.checkApplicationType(this.$data.selectedAppType, 'PYTHON')) {
                return {
                    appName: 'Python',
                    app_short_name: 'jupyterlab',
                    language: 'python',
                    externalConfigLink: 'https://docs.nuvolos.cloud/data/access-data-from-applications#connecting-with-python',
                    nvConnectorLines: this.scriptLines(this.pythonConnectorData.nuvolosConnector),
                    localConnectorLines: this.scriptLines(this.pythonConnectorData.localComputerConnector),
                    connectorLink: 'https://github.com/nuvolos-cloud/python-connector',
                    nuvolosConnector: this.pythonConnectorData.nuvolosConnector,
                    localComputerConnector: this.pythonConnectorData.localComputerConnector
                }
            } else if (this.$data.selectedAppType !== null && this.checkApplicationType(this.$data.selectedAppType, 'RSTUDIO')) {
                return {
                    appName: 'RStudio',
                    app_short_name: 'rstudio',
                    language: 'r',
                    externalConfigLink: 'https://docs.nuvolos.cloud/data/access-data-from-applications#connecting-with-r',
                    nvConnectorLines: this.scriptLines(this.rConnectorData.nuvolosConnector),
                    localConnectorLines: this.scriptLines(this.rConnectorData.localComputerConnector),
                    connectorLink: 'https://github.com/nuvolos-cloud/r-connector',
                    nuvolosConnector: this.rConnectorData.nuvolosConnector,
                    localComputerConnector: this.rConnectorData.localComputerConnector
                }
            } else if (this.$data.selectedAppType !== null && this.checkApplicationType(this.$data.selectedAppType, 'STATA')) {
                return {
                    appName: 'Stata',
                    app_short_name: 'stata',
                    language: 'stata',
                    externalConfigLink: 'https://docs.nuvolos.cloud/data/access-data-from-applications#connecting-with-stata',
                    nvConnectorLines: this.scriptLines(this.stataConnectorData.nuvolosConnector),
                    localConnectorLines: this.scriptLines(this.stataConnectorData.localComputerConnector),
                    nuvolosConnector: this.stataConnectorData.nuvolosConnector,
                    localComputerConnector: this.stataConnectorData.localComputerConnector
                }
            } else if (this.$data.selectedAppType !== null && this.checkApplicationType(this.$data.selectedAppType, 'MATLAB')) {
                return {
                    appName: 'Matlab',
                    app_short_name: 'matlab',
                    language: 'matlab',
                    externalConfigLink: 'https://docs.nuvolos.cloud/data/access-data-from-applications#connecting-with-matlab',
                    connector: this.matlabConnectorData,
                    nvConnectorLines: this.scriptLines(this.matlabConnectorData.nuvolosConnector),
                    localConnectorLines: this.scriptLines(this.matlabConnectorData.localComputerConnector),
                    nuvolosConnector: this.matlabConnectorData.nuvolosConnector,
                    localComputerConnector: this.matlabConnectorData.localComputerConnector
                }
            }
            return null
        },
        pythonConnectorData() {
            return {
                nuvolosConnector: `from nuvolos import get_connection\nimport pandas as pd\nconn = get_connection()\nresult_data = pd.read_sql("${this.queryTextOneLine(
                    'escapeDoubleQuote'
                )}", con=conn)`,
                localComputerConnector: `from nuvolos import get_connection\nimport pandas as pd\nconn = get_connection(dbname="${
                    this.tablesPath.db
                }", schemaname="${this.tablesPath.schema}")\nresult_data = pd.read_sql("${this.queryTextOneLine('escapeDoubleQuote')}", con=conn)`
            }
        },
        rConnectorData() {
            return {
                nuvolosConnector: `conn <- nuvolos::get_connection()\nresult_data <- dbGetQuery(conn,"${this.queryTextOneLine('escapeDoubleQuote')}")`,
                localComputerConnector: `conn <- nuvolos::get_connection(dbname='"${this.tablesPath.db}"', schemaname='"${
                    this.tablesPath.schema
                }"')\nresult_data <- dbGetQuery(conn,"${this.queryTextOneLine('escapeDoubleQuote')}")`
            }
        },
        matlabConnectorData() {
            return {
                nuvolosConnector: `conn = get_connection();\nresult_data = execute_query(conn,'${this.queryTextOneLine('singleToTwoSingle')}');`,
                localComputerConnector: `conn = get_connection('${this.tablesPath.db}', '${
                    this.tablesPath.schema
                }');\nresult_data = execute_query(conn,'${this.queryTextOneLine('singleToTwoSingle')}');`
            }
        },
        stataConnectorData() {
            return {
                nuvolosConnector: `odbc load, exec(\`"${this.queryTextOneLine('singleToTwoSingle')}"') connectionstring($conn_str)`,
                localComputerConnector: `odbc load, exec(\`"${this.queryTextOneLine('singleToTwoSingle')}"') connectionstring($conn_str)`
            }
        }
    },
    watch: {
        getDataDialog: function(nextValue, preValue) {
            if (preValue === true && nextValue === false) {
                this.$data.selectedConnectorOption = this.$data.connectorOptions.FROM_NUVOLOS
            }
        }
    }
}
</script>
